// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement("style");
    msViewportStyle.appendChild(
        document.createTextNode("@-ms-viewport{width:auto!important}")
    );
    document.querySelector("head").appendChild(msViewportStyle);
}

var SkodaApp = (function () {
    var relatedLink = null;

    var pushStateActive = true;

    var justHide = false;

    var isLoading = false;

    var $grid = null;

    var History;

    var initLazyLoad = function () {
        jQuery("[data-src]").on("inview", function () {
            var $this = jQuery(this);
            $this.attr("src", $this.attr("data-src"));
            // Remove it from the set of matching elements in order to avoid that the handler gets re-executed
            $this.removeAttr("data-src");
        });
    };

    var initTypeahead = function () {
        $("#typeahead, #typeahead-mobile").each(function (obj) {
            var $item = $(this);

            var autocompleteSource = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                limit: 15,
                remote: {
                    url: $item.attr("data-source") + "/%QUERY",
                    wildcard: "%QUERY"
                }
            });

            $item
                .typeahead(null, {
                    name: "autocomplete",
                    display: "value",
                    source: autocompleteSource,
                    hint: false,
                    limit: 15,
                    templates: {
                        empty: [
                            '<div class="empty-message">',
                            "No results for this query",
                            "</div>"
                        ].join("\n"),
                        suggestion: Handlebars.compile(
                            '<a href="{{url}}" data-toggle="modal" data-target="#articleDetail"><div class="img"><img class="img-fluid" src="{{img}}" />{{{icon}}}</div><div class="tt-cont"><div class="tt-title">{{{value}}}</div><div class="tt-hinta">{{{hint}}}</div></div></a>'
                        )
                    }
                })
                .on("typeahead:select", function (a, b) {
                    $(this).typeahead("val", "");
                    $("#search-opener").toggleClass("is-active");
                    $("#search-container").toggleClass("is-active");
                });
        });

        //    jQuery("#typeahead, #typeahead-mobile").typeahead(
        //      {
        //        hint: true,
        //        highlight: true,
        //        minLength: 1
        //      },
        //      {
        //        name: "states",
        //        source: substringMatcher(states)
        //      }
        //    );
    };

    var initOpeners = function () {
        jQuery("#nav-opener").click(function () {
            jQuery(this).toggleClass("is-active");
            jQuery("#nav-container").toggleClass("is-active");
            jQuery("html, body").toggleClass("nav-is-active");
        });
    };

    var initSearch = function () {
        jQuery("#search-opener, #search-close, #search-opener-mobile").click(
            function () {
                jQuery("#search-opener").toggleClass("is-active");
                jQuery("#search-container").toggleClass("is-active");
                jQuery("#typeahead").focus();
            }
        );

        var spanSubmit = jQuery("#search-submit");
        spanSubmit.on("click", function () {
            jQuery(this)
                .closest("form")
                .submit();
        });
    };

    var initNav = function () {
        navMaxHeight = jQuery(window).height() - jQuery(".header__top").height();
        jQuery("#nav-container > ul").css("max-height", navMaxHeight);
        jQuery("#nav-container .with-dropdown > span").click(function () {
            if (jQuery(this).hasClass("is-active")) {
                if (window.matchMedia("(max-width: 1065px)").matches) {
                    jQuery(this)
                        .parent()
                        .find("ul")
                        .hide();
                    jQuery(this)
                        .parent()
                        .prevAll()
                        .css("display", "block");
                    jQuery(this)
                        .parent()
                        .nextAll()
                        .css("display", "block");
                    jQuery(this).removeClass("is-active");
                }
            } else {
                if (window.matchMedia("(max-width: 1065px)").matches) {
                    jQuery(this)
                        .parent()
                        .find("ul")
                        .show();
                    jQuery(this)
                        .parent()
                        .prevAll()
                        .css("display", "none");
                    jQuery(this)
                        .parent()
                        .nextAll()
                        .css("display", "none");
                    jQuery(this).addClass("is-active");
                }
            }
        });
    };

    var initModalContent = function (frame) {
        var frame = frame === undefined || !frame.length ? $(document) : frame;

        $(".video__overlayer", frame).click(function () {
            $(this).hide();
            $(this)
                .parent()
                .find("video")
                .get(0)
                .play();
        });
        $(".tabs__opener", frame).click(function () {
            $(this).toggleClass("is-active");
            $(this)
                .parent()
                .find(".row")
                .fadeToggle();
        });

        $('.modal__tabs a[data-toggle="tab"], .nav-tabs a[data-toggle="tab"]', frame).on('shown.bs.tab', function (e) {
            dataLayer.push({'event': 'articleTabClick'});
        });

        $(".modal--slider", frame).slick({
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true
        });

        $(".slider", frame).slick({
            dots: true
        });

        $(".gallery__main-slider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            centerMode: true,
            asNavFor: ".gallery__thumb-slider",
            focusOnSelect: true,
            centerPadding: "120px",
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: false,
                        adaptiveHeight: true
                    }
                }
            ]
        });

        $(".gallery__main-slider").on('afterChange', function(event, slick, currentSlide){
            dataLayer.push({'event': 'galleryClick'});
        });

        $(".gallery__thumb-slider").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: ".gallery__main-slider",
            dots: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });

        $('a.anchorLink').on('click', function(ev){
            ev.preventDefault();
            $evoker = $(this);
            $container = $("article.modal.show:first");
            if($evoker.data('anchor-target')) {
                var myOffset = $('[data-anchor="' + $evoker.data('anchor-target') + '"]', $container).length ? $('[data-anchor="' + $evoker.data('anchor-target') + '"]', $container).position().top : 20;
            }else{
                var $innerH = $('[data-anchors-container="'+$evoker.data('anchor-container')+'"] h3', $container).eq($evoker.data('anchor-num'));
                var myOffset = $innerH.length ? $innerH.position().top : 20;
            }
            $container.animate(
                {
                    scrollTop: myOffset + 20
                },
                500
            );
        });

        hotSpots();

    };

    var extractCathPath = function (path) {
        var regex = /(\/.*?\/.*?)(\/.*?)(.html)/gm;

        if (regex.exec(path)) {
            return path.replace(regex, '$1$3');
        }
        return path;
    };

    var initModals = function () {
        $("#articleDetail").on("show.bs.modal", function (event) {
            var $modal = $(this);
            var $evoker = relatedLink || $(event.relatedTarget);
            var isRelated = false;
            if (relatedLink) {
                relatedLink = null;
                isRelated = true;
            }

            if (!$modal.is(".filled")) {
                var timer = setTimeout(function () {
                    $(".modal-backdrop").addClass("loading");
                }, 0);

                //load content
                $.ajax({
                    url: $evoker.attr("href"),
                    type: "POST",
                    beforeSend: function () {
                        $modal.addClass("blocked");
                    },
                    success: function (data) {
                        $(".modal-body-content", $modal).html(data.content);
                        $(".breadcrumb", $modal).html(data.breadcrumb);
                        setTimeout(function () {
                            initModalContent($modal);
                        }, 150);

                        //history
                        if (pushStateActive) {
                            History.pushState({
                                'modalUrl': data.url,
                                'cathUrl': (isRelated ? window.history.state.cathUrl : window.location.pathname)
                            }, data.title, data.url);
                        } else {
                            pushStateActive = true;
                        }
                    },
                    complete: function () {
                        $("#articleDetail").imagesLoaded({ background: '.gal-img' },function () {
                            $modal.removeClass("blocked");
                            // $(".modal-backdrop").addClass("complete");
                            // $(".modal-backdrop").addClass("bg");
                            clearTimeout(timer);
                            $(".modal-backdrop").removeClass("loading");
                        });
                    }
                });
            } else {
                //history
                History.replaceState({
                    'modalUrl': window.location.pathname,
                    'cathUrl': extractCathPath(window.location.pathname)
                }, document.title, window.location.pathname);
            }
        });

        window.addEventListener('popstate', function (event) {
            //je to prechod back tlacitkem, nechci vkladat
            pushStateActive = false;
            if (event.state && event.state.modalUrl) {

                relatedLink = $('<a>');
                relatedLink.attr('href', event.state.modalUrl);

                if ($('#articleDetail').hasClass('show')) {
                    $("#articleDetail").modal('hide');
                } else {
                    $("#articleDetail").modal({'show': true});
                }
            } else {
                if ((event.state && !event.state.modalUrl && $('#articleDetail').hasClass('show')) || (!event.state && $('#articleDetail').hasClass('show'))) {
                    relatedLink = null;
                    justHide = true;
                    $("#articleDetail").modal('hide');
                }
            }
        });

        $("#articleDetail").on("shown.bs.modal", function (event) {
            $modal = $(this);
            if ($modal.is(".filled")) {
                initModalContent($modal);
            }
        });

        $("#articleDetail").on("hidden.bs.modal", function (e) {
            var $modal = $(this);
            if ($modal.is(".filled")) {
                $modal.removeClass("filled");
            }
            $(".modal-body-content", $modal).html("");
            $(".breadcrumb", $modal).html("");

            if (pushStateActive && window.history.state.cathUrl && !relatedLink) {
                History.pushState({
                    'modalUrl': false,
                    'cathUrl': false
                }, '', window.history.state.cathUrl);
            }

            if (!justHide) {
                if (relatedLink) {
                    $("#articleDetail").modal({'show': true});
                }
            }
            justHide = false;
        });

        $(document).on("click", "a.back", function (ev) {
            ev.preventDefault();

            SkodaApp.smoothScroll($("article.modal.show:first"), 0);
        });

        //open
        if ($("#articleDetail.filled").length) {
            $("#articleDetail.filled").modal({'show': true});
        }

        $(document).on("click", ".relatedLink", function (ev) {
            $evoker = $(this);

            ev.preventDefault();

            $("#articleDetail").modal('hide');

            dataLayer.push({'event': 'relatedContent'});

            setTimeout(function () {
                relatedLink = $evoker;
            }, 250);
        });
    };

    var initTiles = function () {
        var elem = document.querySelector("#tiles");
        if (elem) {
            $grid = new Packery(elem, {
                itemSelector: "#tiles article",
                gutter: 0
            });
        }
    };

    var autoScrollInit = function () {
        if (window.location.hash) {
            if ($(window.location.hash).length) {
                SkodaApp.smoothScroll($(window.location.hash));
            }
            var $alter = $('*[data-hash="' + window.location.hash.substr(1) + '"]');
            if ($alter.length) {
                SkodaApp.smoothScroll($alter);
            }
        }
    };

    var initVisions = function () {
        $(".js-visions").slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 10000,
            dots: true,
            draggable: false,
            fade: true,
            swipe: false,
            touchMove: false
        });
    };

    var isListing = function()
    {
        return $('#itemRows').length ? true : false;
    }

    var initLoadMore = function () {
        $('.loadMore').on('click', function (ev) {
            ev.preventDefault();

            if (isLoading) {
                return;
            }

            var $evoker = $(this);

            if(isListing()) {
                numLoaded = $('#itemRows > li').length;
            }else{
                //numLoaded = $('#tiles > .col-xl-6').length * 2 + $('#tiles > .col-xl-3').length;
                numLoaded = $('#tiles > .col-md-4').length;
            }

            //load content
            $.ajax({
                url: $evoker.attr("href"),
                type: "POST",
                data: {
                    loaded: numLoaded
                },
                beforeSend: function () {
                    isLoading = true;
                    $evoker.addClass("blocked");
                },
                success: function (data) {

                    if(isListing()) {
                        $('#itemRows').append(data.html);
                    }else {
                        $('#tiles').append(data.html);

                        initTiles();
                    }

                    if (!data.loadMore) {
                        $evoker.fadeOut('fast');
                    }
                },
                complete: function () {
                    isLoading = false;
                    $evoker.removeClass("blocked");
                }
            });
        });
    };


    var hotSpots = function () {

        var isAnyActiveBeforeStart = ($(".hotspot-content .tab-pane.active").length > 0) ? true : false;
        if( isAnyActiveBeforeStart === true){
          
                   $(".hotspot-content").css("min-height", "300px");
               
        }

         $(".hotspot").on('click', '.anch', function (ev) {
            ev.preventDefault();

            var toggleTab = $(this).attr("data-tab");
            var activeAnch = $(this);
            var isActive = ($("#"+toggleTab).hasClass("active")) ? true : false;
            var isCloseToggler = ($(this).hasClass("toggleSpot")) ? true : false;
            var isAnyActive = ($(".hotspot-content .tab-pane.active").length > 0) ? true : false;
            var delay = 0;
            var scrollTopSet = 0;
            var scrollObject = $(".hotspot-map");
            var hotSpotHeight = $(".hotspot-map").height();
            var anotherOpen = false;

            if(isAnyActive === true){
                $(".hotspot-content .tab-pane.active").removeClass("active");
                $(".hotspot-content .tab-pane.show").removeClass("show");
                $(".hotspot-map .anch.active").removeClass("active");
                delay = 1500;
                scrollTopSet = $(scrollObject).position().top;
            }

            if(isActive !== true && toggleTab !== undefined && isCloseToggler !== true){
                scrollTopSet = $(scrollObject).position().top + hotSpotHeight;
                anotherOpen = true;
                window.setTimeout(function(){
                    $(".hotspot-content .tab-pane#"+toggleTab).addClass("show");
                    $(".hotspot-content .tab-pane#"+toggleTab).addClass("active");
                    $(activeAnch).addClass("active");
                    $(".hotspot-content").css("min-height", "300px");
                }, delay);
            }

            window.setTimeout(function(){
               if(scrollTopSet > 0){

                    $('#articleDetail').animate({ scrollTop: scrollTopSet }, 1000);

                }

               

                if(anotherOpen === false){
                    $(".hotspot-content").css("min-height","");
                }
            }, 800);

            $(".hotspot-content video").each(function () { 
                this.pause() 
            });

        });
        $(".hotspot").on('click', '.arrow', function (ev) {
            ev.preventDefault();

            var rotateAction = ($(this).hasClass("right")) ? "next" : "prev";
            var parentTab = $(this).closest(".tab-pane");
            var enableNext = ($(parentTab).next().length > 0) ? true : false;
            var enablePrev = ($(parentTab).prev().length > 0) ? true : false;

            if(parentTab !== undefined){
                $(".hotspot-content .tab-pane.active").removeClass("active");
                $(".hotspot-content .tab-pane.show").removeClass("show");
                $(".hotspot-map .anch.active").removeClass("active");

                window.setTimeout(function(){
                    if(rotateAction === "next"){
                        (enableNext === true) ? $(parentTab).next().addClass("active show") : $(parentTab).closest(".hotspot-content").find(">:first-child").addClass("active show");
                    }else{
                        (enablePrev === true) ? $(parentTab).prev().addClass("active show") : $(parentTab).closest(".hotspot-content").find(">:last-child").addClass("active show");
                    }
                    var highlightAnchId = $(".hotspot-content .tab-pane.active").attr("id");
                    $(".anch[data-tab="+highlightAnchId+"]").addClass("active");
                }, 1000);

                window.setTimeout(function(){
                   $(".hotspot-content").css("min-height", $(".hotspot-content .tab-pane.active").height()+"px");
               }, 1500);
            }
            
            $(".hotspot-content video").each(function () { 
                this.pause() 
            });

        });
    };

    return {
        //main function to initiate the module
        init: function () {
            History = window.history;

            initLazyLoad();
            initTypeahead();
            initOpeners();
            initSearch();
            initNav();
            initModals();
            initTiles();
            initVisions();
            initLoadMore();
            hotSpots();

            jQuery(window).resize(function () {
                navMaxHeight =
                    jQuery(window).height() - jQuery(".header__top").height();
                jQuery("#nav-container > ul").css("max-height", navMaxHeight);
            });
        },

        smoothScroll: function (frameParam, targetParam) {
            var frame =
                frameParam === undefined || !frameParam.length
                    ? $("body, html")
                    : frameParam;
            var off = targetParam.length
                ? targetParam.offset().top
                : parseInt(targetParam);
            frame.animate(
                {
                    scrollTop: off
                },
                1000
            );
        }
    };
})();

jQuery(document).ready(function () {
    SkodaApp.init();
});

jQuery(document).scroll(function () {
    if (jQuery(document).scrollTop() >= 60) {
        jQuery(".header__top, .header .nav").addClass("scrolled");
    } else {
        jQuery(".header__top, .header .nav").removeClass("scrolled");
    }
});

window.onload = function() {


  $('.cross-links article').addClass('loaded');
}





