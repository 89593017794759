var myCenter = new google.maps.LatLng(50.0586274, 14.2760478);
var image = {
  url: "/assets/images/content/pin.png",
  size: new google.maps.Size(57, 68),
  scaledSize: new google.maps.Size(47, 56),
  origin: new google.maps.Point(-5, -5)
};
var marker;

function map() {
  var mapProp = {
    center: myCenter,
    zoom: 15,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
	fullscreenControl: true,
	fullscreenControlOptions: {
		position: google.maps.ControlPosition.RIGHT_BOTTOM
	},
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#212121"
          }
        ]
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575"
          }
        ]
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#212121"
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#757575"
          }
        ]
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e"
          }
        ]
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd"
          }
        ]
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575"
          }
        ]
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#181818"
          }
        ]
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161"
          }
        ]
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#1b1b1b"
          }
        ]
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#2c2c2c"
          }
        ]
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8a8a8a"
          }
        ]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#373737"
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#3c3c3c"
          }
        ]
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#4e4e4e"
          }
        ]
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161"
          }
        ]
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#000000"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#3d3d3d"
          }
        ]
      }
    ]
  };

  var map = new google.maps.Map(document.getElementById("map"), mapProp);

  var marker = new google.maps.Marker({
    position: new google.maps.LatLng(50.056251, 14.278427),
    // icon: "/assets/images/content/pin.png"
    icon: image,
	clickable: false
  });

  marker.setMap(map);

  // Overlay view allows you to organize your markers in the DOM
  // https://developers.google.com/maps/documentation/javascript/reference#OverlayView
  var myoverlay = new google.maps.OverlayView();
  myoverlay.draw = function() {
    // add an id to the layer that includes all the markers so you can use it in CSS
    this.getPanes().markerLayer.id = "markerLayer";
  };
  myoverlay.setMap(map);

  $("#markerLayer img").css("transition", "transform 1s ease-in-out");

//  marker.addListener("mouseover", function() {
//    $("#markerLayer img").css("transform", "scale(1.2)");
//  });
//
//  marker.addListener("mouseout", function() {
//    $("#markerLayer img").css("transform", "scale(1)");
//  });
}

google.maps.event.addDomListener(window, "load", map);

jQuery(document).ready(function() {
  footerContentHeight = jQuery(".footer__content").outerHeight();
  jQuery("#map").css("height", footerContentHeight);
});

jQuery(window).resize(function() {
  footerContentHeight = jQuery(".footer__content").outerHeight();
  jQuery("#map").css("height", footerContentHeight);
});
